import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Card } from "flowbite-react";
import programs from "../utils/data/programs";

const customTheme = {
  img: {
    horizontal: {
      off: "rounded-t-lg max-h-40 object-cover h-96 w-full object-center",
      on: "h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg",
    },
  },
};

const responsiveSettings = [
  {
    breakpoint: 1000,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 645,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
];
function Programs() {
  return (
    <section className="md:px-24 text-white">
      <h1 className="text-center md:text-left text-5xl font-black">
        OTHER CHARTS
      </h1>
      <div className=" my-12">
        <Slide
          slidesToScroll={1}
          slidesToShow={1}
          pauseOnHover={true}
          responsive={responsiveSettings}
        >
          {programs.map((p) => (
            <Card
              imgAlt={p.title}
              imgSrc={p.img}
              className="max-w-xs"
              theme={customTheme}
              key={p.title}
            >
              <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                <p>{p.title}</p>
              </h5>
              <p className="font-normal text-gray-700 dark:text-gray-400">
                <span>{p.description}...</span>
              </p>
            </Card>
          ))}
        </Slide>
      </div>
    </section>
  );
}

export default Programs;
