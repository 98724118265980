import React from "react";

function Producer({ size }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 32 32"
        >
            <path
                fill="#fcefd1"
                d="M6.225 17.408a.45.45 0 01-.45-.458v-2.683c0-.842.692-1.533 1.533-1.533s1.533.692 1.533 1.533a.455.455 0 11-.908 0c0-.342-.283-.625-.625-.625s-.625.283-.625.625v2.683a.457.457 0 01-.458.458z"
            ></path>
            <path
                fill="#dccae0"
                d="M25.642 17.408c-.25 0-.458-.2-.458-.458v-1.892c0-.25.2-.45.458-.45s.45.2.45.45v1.892a.445.445 0 01-.45.458z"
            ></path>
            <path
                fill="#f7e4cc"
                d="M9.458 20.633A1.54 1.54 0 017.925 19.1v-3.5c0-.25.2-.45.458-.45.25 0 .45.2.45.45v3.5c0 .342.283.625.625.625s.625-.283.625-.625v-8.417c0-.842.692-1.533 1.533-1.533s1.533.692 1.533 1.533c0 .25-.2.458-.458.458a.45.45 0 01-.45-.458c0-.342-.283-.625-.625-.625s-.625.283-.625.625V19.1c0 .85-.683 1.533-1.533 1.533z"
            ></path>
            <path
                fill="#fddbc5"
                d="M13.775 18.517a1.54 1.54 0 01-1.533-1.533v-4.967a.455.455 0 01.908 0v4.967c0 .342.283.625.625.625s.625-.283.625-.625v-4.092c0-.842.692-1.533 1.533-1.533s1.533.692 1.533 1.533c0 .25-.2.458-.458.458a.45.45 0 01-.45-.458c0-.342-.283-.625-.625-.625s-.625.283-.625.625v4.092c0 .85-.683 1.533-1.533 1.533z"
            ></path>
            <path
                fill="#f3d4e3"
                d="M18.092 23.267a1.54 1.54 0 01-1.533-1.533v-7.5a.455.455 0 01.908 0v7.5c0 .342.283.625.625.625s.625-.283.625-.625V6.617c0-.842.692-1.533 1.533-1.533s1.533.692 1.533 1.533c0 .25-.2.45-.458.45-.25 0-.458-.2-.458-.45 0-.342-.283-.625-.625-.625s-.625.283-.625.625v15.117a1.517 1.517 0 01-1.525 1.533z"
            ></path>
            <path
                fill="#e1cfe5"
                d="M22.408 17.733a1.54 1.54 0 01-1.533-1.533V7.95c0-.25.2-.45.458-.45.25 0 .458.2.458.45v8.242c0 .342.283.625.625.625s.625-.283.625-.625v-2.467c0-.842.692-1.533 1.533-1.533s1.533.692 1.533 1.533a.455.455 0 11-.908 0c0-.342-.283-.625-.625-.625s-.625.283-.625.625v2.467c-.008.85-.7 1.542-1.542 1.542z"
            ></path>
            <path
                fill="#fcc628"
                d="M8.025 16.808a.365.365 0 01-.367-.367v-2.192c0-.692.558-1.25 1.25-1.25s1.25.558 1.25 1.25a.37.37 0 01-.367.367.365.365 0 01-.367-.367c0-.283-.225-.508-.508-.508s-.508.225-.508.508v2.183a.386.386 0 01-.383.375z"
            ></path>
            <path
                fill="#8d278b"
                d="M23.842 16.808a.365.365 0 01-.367-.367v-1.542c0-.208.167-.367.367-.367.208 0 .367.167.367.367v1.542a.36.36 0 01-.367.367z"
            ></path>
            <path
                fill="#ec9b36"
                d="M10.667 19.433c-.692 0-1.25-.558-1.25-1.25v-2.85c0-.208.167-.367.367-.367.208 0 .367.167.367.367v2.85c0 .283.225.508.508.508s.508-.225.508-.508v-6.85c0-.692.558-1.25 1.25-1.25s1.25.558 1.25 1.25a.365.365 0 01-.367.367.365.365 0 01-.367-.367c0-.283-.225-.508-.508-.508s-.508.225-.508.508v6.858a1.258 1.258 0 01-1.25 1.242z"
            ></path>
            <path
                fill="#f16522"
                d="M14.175 17.717c-.692 0-1.25-.558-1.25-1.25v-4.05c0-.208.167-.367.367-.367.208 0 .367.167.367.367v4.05c0 .283.225.508.508.508s.508-.225.508-.508v-3.333c0-.692.558-1.25 1.25-1.25s1.25.558 1.25 1.25a.37.37 0 01-.367.367.365.365 0 01-.367-.367c0-.283-.225-.508-.508-.508s-.508.225-.508.508v3.333c0 .692-.558 1.25-1.25 1.25z"
            ></path>
            <path
                fill="#e1288b"
                d="M17.692 21.583c-.692 0-1.25-.558-1.25-1.25v-6.108c0-.208.167-.367.367-.367.208 0 .367.167.367.367v6.108c0 .283.225.508.508.508s.508-.225.508-.508V8.016c0-.692.558-1.25 1.25-1.25s1.25.558 1.25 1.25a.365.365 0 01-.367.367.365.365 0 01-.367-.367c0-.283-.225-.508-.508-.508s-.508.225-.508.508v12.317c0 .692-.558 1.25-1.25 1.25z"
            ></path>
            <path
                fill="#b33894"
                d="M21.208 17.075c-.692 0-1.25-.558-1.25-1.25V9.108c0-.208.167-.367.367-.367s.367.167.367.367v6.717c0 .283.225.508.508.508s.508-.225.508-.508v-2.008c0-.692.558-1.25 1.25-1.25s1.25.558 1.25 1.25a.37.37 0 01-.367.367.365.365 0 01-.367-.367c0-.283-.225-.508-.508-.508s-.508.225-.508.508v2.008c0 .683-.558 1.25-1.25 1.25z"
            ></path>
            <path
                fill="#3e4e64"
                d="M4.183 26.908l1.3-2.617h1.158l1.3 2.617h-.958l-.217-.475H5.358l-.242.475h-.933zM5.6 25.825h.908l-.433-.95h-.042l-.433.95zM8.325 26.908v-2.617h.85v2.617h-.85zM11.658 26.908v-2.017H10.6v-.608h2.992v.608h-1.067v2.017h-.867zM14.892 26.908c-.317 0-.542-.067-.683-.208s-.217-.367-.217-.675v-.85c0-.3.075-.525.217-.667s.375-.217.692-.217h1.3c.308 0 .542.075.683.217s.217.367.217.667v.85c0 .308-.075.533-.217.675s-.375.208-.683.208h-1.308zm.308-.6h.692c.233 0 .35-.108.35-.317v-.775c0-.208-.117-.308-.35-.308H15.2c-.117 0-.208.025-.267.075s-.092.125-.092.242V26c0 .108.033.192.092.242.058.042.15.067.267.067zM17.8 26.908V24.65l-.042-.358h2.058c.2 0 .358.025.475.067s.208.1.275.175c.058.075.1.158.117.25.017.1.025.2.025.308v.35c0 .142-.025.275-.067.392a.644.644 0 01-.25.292c-.125.075-.3.108-.533.108h-.308c-.183 0-.358-.008-.525-.017a7.204 7.204 0 01-.358-.025v.717H17.8zm.867-1.283h.917c.1 0 .167-.017.208-.058s.058-.1.058-.183v-.225c0-.083-.017-.15-.05-.192s-.1-.058-.208-.058h-.925v.717zM22.9 26.908v-.392h-1.742v-.517l1.508-1.7h1.1v1.617h.592v.608h-.592v.392h-.867zm-.667-1h.658v-.8h-.042l-.617.758v.042zM25.975 26.908c-.358 0-.608-.067-.758-.208s-.225-.367-.225-.675v-.85c0-.308.075-.533.225-.675s.408-.208.767-.208h.85c.358 0 .608.067.758.208s.225.367.225.675v.85c0 .308-.075.533-.225.675s-.408.208-.758.208h-.858zm.167-.6h.508c.108 0 .183-.025.233-.083s.067-.133.067-.233v-.775c0-.083-.025-.158-.075-.217s-.125-.092-.233-.092h-.508c-.108 0-.183.025-.233.083s-.075.133-.075.225v.775c0 .1.025.175.075.233.058.058.133.083.242.083z"
            ></path>
            <path
                fill="transparent"
                d="M32 29.442V32H0V0h32v2.608c-.025.008-.042.008-.067.017-.058 0-.125-.008-.183-.008H20.275c-.083 0-.167.008-.25.017-1.175 0-2.342-.008-3.517-.008-2.808 0-5.625.008-8.433.008-.033-.008-.058-.017-.092-.017-2.567 0-5.133 0-7.7-.008-.208 0-.233.1-.233.267.008 4.383.008 8.767.008 13.15v13.183c0 .067.025.133.033.225h6.925c.367 0 .725-.008 1.092-.017.125 0 .25.008.375.008h9.725c.6 0 1.208-.008 1.808-.008.125.008.25.017.375.017h8.4c1.05 0 2.092-.008 3.142-.008.025.008.05.017.067.017z"
            ></path>
            <path
                fill="#60158b"
                d="M20.025 2.633c.083-.008.167-.017.25-.017H31.75c.058 0 .125.008.183.008.025.017.042.033.067.05v13.35H20.425c-.133 0-.267-.008-.4-.008V2.633zm7.233 6.559c0-1.417-1.183-2.608-2.6-2.575-1.433.025-2.575 1.108-2.558 2.575.017 1.483 1.092 2.575 2.592 2.575 1.458-.008 2.508-1.092 2.567-2.575z"
            ></path>
            <path
                fill="#b1a6e1"
                d="M20.017 16.017c.133.008.267.008.4.008h11.575v13.35c-.017.017-.042.033-.058.05-1.05 0-2.092.008-3.142.008h-8.4c-.125 0-.25-.008-.375-.017v-3.9l.933-.075c.025-.1.067-.208.067-.308.008-.725.008-1.45.008-2.175.175 0 .35.008.525-.017.075-.008.208-.1.208-.158.017-.567.008-1.125.008-1.675-.275-.033-.517-.058-.758-.083 0-.542.008-1.092.008-1.633 0-.042-.042-.075-.058-.117-.308-.008-.625-.008-.933-.017v-3.225l-.008-.017zm5.066 8.25v1.183h1.933v-1.242c.175 0 .35-.008.525 0 .2.017.283-.058.275-.267-.008-.517-.008-1.033-.008-1.55a.374.374 0 01-.05-.058c-.225-.008-.458-.017-.683-.017l-.05-.05v-3.033h-1.892v3.025l-.742.075a.374.374 0 00-.05.058v1.808h.742v.067z"
            ></path>
            <path
                fill="#9c29fe"
                d="M32 2.675c-.025-.017-.042-.033-.067-.05.025-.008.042-.008.067-.017v.067z"
            ></path>
            <path
                fill="#ccb0fe"
                d="M31.942 29.425c.017-.017.042-.033.058-.05v.058c-.017.008-.042 0-.058-.008z"
            ></path>
            <path
                fill="#8150d2"
                d="M20.025 2.633c0 4.458 0 8.917-.008 13.383v.025c-.05-.008-.1-.017-.158-.017H8.101c-.017-.008-.025-.008-.042-.008-.008-.125-.017-.25-.017-.375V2.999c0-.125.017-.25.025-.375 2.808 0 5.625-.008 8.433-.008 1.183.008 2.35.017 3.525.017zm-2.058 6.584c0-1.442-1.15-2.633-2.567-2.608-1.558.025-2.583 1.158-2.583 2.592a2.526 2.526 0 002.517 2.567c1.517.025 2.575-1.117 2.633-2.55z"
            ></path>
            <path
                fill="#e1b3fe"
                d="M8.108 16.033h11.758c.05 0 .1.008.158.017v3.225l.008.017h-.942v1.792h-.775v1.842c.275.017.525.025.783.042 0 .017 0 .042.008.058v2.433l.05.05h.875l-.008.017v3.9c-.6 0-1.208.008-1.808.008H8.49c-.125 0-.25-.008-.375-.008-.025-.15-.067-.3-.067-.458V16.743c0-.242.042-.475.058-.708zm4.925 8.175v1.242h1.883v-1.242c.2 0 .392.017.583-.017.083-.008.208-.117.217-.183.017-.55.008-1.108.008-1.7h-.8v-3.075h-1.883v3.025c-.25.033-.5.067-.767.1V24.2c.258.008.508.008.758.008z"
            ></path>
            <path
                fill="#fed0fd"
                d="M8.075 2.633c-.008.125-.025.25-.025.375V15.65c0 .125.008.25.017.375H5v-.917H3.092v.917c-1.017 0-2.025 0-3.042.008 0-4.383 0-8.767-.008-13.15 0-.167.017-.267.233-.267 2.567.008 5.133.008 7.7.008.042-.008.067 0 .1.008zm-3.092 8.459h-1.85v1.85h1.85v-1.85zm-.016-2.175v-1.85H3.142v1.85h1.825z"
            ></path>
            <path
                fill="#f4c4fe"
                d="M5.058 16.025h3.017c.017 0 .033 0 .042.008-.025.233-.067.475-.067.708v12.225c0 .15.042.3.067.458-.367.008-.725.017-1.092.017H.1c-.017-.083-.033-.15-.033-.225V16.033c1.017 0 2.025 0 3.042-.008h.025v.925h1.867c.017-.308.033-.617.058-.925zm-1.933 4.933h1.842v-1.842H3.125v1.842zm1.858 2.184H3.141v1.833h1.842v-1.833z"
            ></path>
            <path
                fill="#2c0b3f"
                d="M27.258 9.192c-.058 1.483-1.1 2.575-2.567 2.575-1.508 0-2.575-1.092-2.592-2.575-.017-1.467 1.125-2.55 2.558-2.575 1.417-.033 2.6 1.158 2.6 2.575z"
            ></path>
            <path
                fill="#480d57"
                d="M24.392 22.342l.742-.075c.108.025.217.058.325.058.517.008 1.033 0 1.558 0 .025 0 .042 0 .067-.008.225.008.458.017.683.017a.374.374 0 01.05.058c0 .517 0 1.033.008 1.55.008.208-.083.275-.275.267-.175-.008-.35 0-.525 0h-2.676v-1.808a.138.138 0 01.042-.058z"
            ></path>
            <path
                fill="#d220fe"
                d="M27.008 22.325H25.45c-.108 0-.217-.042-.325-.058v-3.025h1.892v3.033c-.008.008-.008.025-.008.05z"
            ></path>
            <path
                fill="#480d57"
                d="M21.008 21.025c.242.025.475.058.758.083 0 .55.008 1.117-.008 1.675 0 .058-.125.142-.208.158-.175.025-.35.017-.525.017-.025 0-.042-.008-.067-.008h-.933v-1.867c.308-.025.625-.042.933-.067.017 0 .033.008.05.008z"
            ></path>
            <path
                fill="#d220fe"
                d="M25.142 24.2h1.867v1.242h-1.933v-1.183a.161.161 0 00.067-.058zM20.025 22.95h.933c0 .833-.008 1.658-.008 2.492l-.933.075.008-.017v-2.55zM20.958 21.017c-.308.025-.625.042-.933.067v-1.8l-.008-.017c.308.008.625.008.933.017.008.575.008 1.15.008 1.733z"
            ></path>
            <path
                fill="#3e364a"
                d="M20.95 25.442c0-.833.008-1.658.008-2.492.025 0 .042.008.067.008 0 .725 0 1.45-.008 2.175 0 .1-.042.208-.067.308zM20.958 21.017v-1.742c.025.042.058.075.058.117 0 .542 0 1.092-.008 1.633-.017 0-.033-.008-.05-.008z"
            ></path>
            <path
                fill="#8379a2"
                d="M27.008 22.325c-.008-.025 0-.042.008-.058l.05.05c-.017 0-.033.008-.058.008z"
            ></path>
            <path
                fill="#41185d"
                d="M27.808 22.392zM24.392 22.342zM25.142 24.2c-.017.025-.042.042-.058.067 0-.025 0-.042-.008-.067h.067z"
            ></path>
            <path
                fill="#60158b"
                d="M17.967 9.217c-.05 1.433-1.108 2.567-2.633 2.55A2.526 2.526 0 0112.817 9.2c0-1.433 1.025-2.567 2.583-2.592 1.417-.017 2.567 1.175 2.567 2.608z"
            ></path>
            <path
                fill="#d220fe"
                d="M14.925 22.317h.8c0 .592.008 1.142-.008 1.7 0 .067-.133.167-.217.183-.192.025-.392.008-.583.017h-2.65v-1.842c.267-.033.517-.067.767-.1.075.017.15.058.225.058.558-.008 1.108-.017 1.667-.017z"
            ></path>
            <path
                fill="#ed35fe"
                d="M14.925 22.317c-.55 0-1.108.008-1.658.008-.075 0-.15-.033-.225-.058v-3.025h1.883v3.075z"
            ></path>
            <path
                fill="#d220fe"
                d="M20.025 21.083v1.867c-.292 0-.583 0-.875.008a.073.073 0 00-.058 0c-.258-.017-.508-.025-.783-.042v-1.842h.775c.317 0 .625 0 .942.008z"
            ></path>
            <path
                fill="#ed35fe"
                d="M19.15 22.958c.292 0 .583 0 .875-.008 0 .85 0 1.7.008 2.55h-.875l-.05-.05v-2.433a.138.138 0 01.042-.058zM13.033 24.208h1.883v1.242h-1.883v-1.242zM20.025 21.083c-.308 0-.625-.008-.933-.008v-1.792h.942c-.008.6-.008 1.2-.008 1.8z"
            ></path>
            <path
                fill="#ed35fe"
                d="M19.15 22.958a.374.374 0 00-.05.058c-.008-.017-.017-.042-.008-.058.017-.008.033 0 .058 0zM19.1 25.45l.05.05-.05-.05zM4.983 11.092v1.85h-1.85v-1.85h1.85zM4.967 8.917H3.142v-1.85h1.825v1.85zM3.1 16.025v-.917h1.908v.917H3.1z"
            ></path>
            <path
                fill="#fe8bfd"
                d="M5.058 16.025c-.017.308-.042.617-.058.933-.008-.308-.008-.617-.017-.933H5.058z"
            ></path>
            <path
                fill="#d220fe"
                d="M3.125 20.958v-1.842h1.842v1.842H3.125zM4.983 23.142v1.833H3.141v-1.833h1.842zM4.975 16.025c.008.308.008.617.017.933H3.125v-.925c.617-.008 1.233-.008 1.85-.008z"
            ></path>
        </svg>
    );
}

export default Producer;