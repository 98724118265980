import {
  faApple,
  faFacebook,
  faGooglePlay,
  faSpotify,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function Footer() {
  return (
    <footer
      className="pb-28  flex flex-col md:flex-row bg-[#312e81] p-4"
      style={{ justifyContent: "space-between" }}
    >
      {/* <img src={Logo} alt="radio klf" className="w-20 bg-white" /> */}
      <div className="flex flex-col my-3 md:my-0">
        <h6
          className="text-3xl font-extrabold uppercase text-white"
          style={{ fontSize: "18px" }}
        >
          AI TOP40
        </h6>
        <div>
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faPhone}
              className="cursor-pointer pr-2"
              style={{ color: "white" }}
            />
            <p style={{ color: "white", fontSize: "15px" }}>
              Request Line (310) 243-6467
            </p>
          </div>
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faWhatsapp}
              className="cursor-pointer pr-2"
              style={{ color: "white" }}
            />
            <p style={{ color: "white", fontSize: "15px" }}>
              Telegram https://t.me/+HX28zXRQSgExYTRh
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:w-1/5 my-3 md:my-0">
        <h2
          className="text-3xl font-extrabold uppercase text-white"
          style={{ fontSize: "18px" }}
        >
          Streams
        </h2>
        <div className="flex text-3xl mt:2 md:mt-8" style={{ marginTop: 15 }}>
          <FontAwesomeIcon
            icon={faGooglePlay}
            className="hover:-translate-y-2 ease-in-out cursor-pointer"
            style={{ color: "white", marginRight: "30px" }}
          />
          <FontAwesomeIcon
            icon={faApple}
            className="hover:-translate-y-2 ease-in-out cursor-pointer"
            style={{ color: "white", marginRight: "30px" }}
          />
          <FontAwesomeIcon
            icon={faSpotify}
            className="hover:-translate-y-2 ease-in-out cursor-pointer"
            style={{ color: "white" }}
          />
        </div>
      </div>
      <div className="flex flex-col my-3 md:my-0">
        <h6
          className="text-3xl font-extrabold uppercase text-white"
          style={{ fontSize: "18px" }}
        >
          Socials
        </h6>
        <div className="flex text-3xl  mt:2 md:mt-8" style={{ marginTop: 15 }}>
          <FontAwesomeIcon
            icon={faFacebook}
            className="hover:-translate-y-2 ease-in-out cursor-pointer"
            style={{ color: "white", marginRight: "30px" }}
          />
          <FontAwesomeIcon
            icon={faTwitter}
            className="hover:-translate-y-2 ease-in-out cursor-pointer"
            style={{ color: "white" }}
          />
        </div>
      </div>
      <div className="flex flex-col my-3 md:my-0">
        <h6
          className="text-3xl font-extrabold uppercase text-white"
          style={{ fontSize: "18px" }}
        >
          Share
        </h6>
        <div
          className="flex text-3xl  items-center mt:2 md:mt-8"
          style={{ marginTop: 15 }}
        >
          <FontAwesomeIcon
            icon={faFacebook}
            className="hover:-translate-y-2 ease-in-out cursor-pointer"
            style={{ color: "white", marginRight: "30px" }}
          />
          <FontAwesomeIcon
            icon={faTwitter}
            className="hover:-translate-y-2 ease-in-out cursor-pointer"
            style={{ color: "white" }}
          />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
