import React from 'react'

const HeaderText = () => {
    return (
        <div className='mt-[-36px] flex justify-center flex-col items-center p-7  md:p-14 md:px-24'>
            <div className='w-full flex justify-center'>
                <p className='font-extrabold uppercase text-5xl text-center text-theme-third pb-5 md:pb-0 first-letter max-w-5xl'>The world’s first chart show produced & presented by AI</p>
            </div>
            <div className='w-full pl-10 flex justify-center'>
                <p className='pt-4  text-gray-300 max-w-7xl text-center text-xl'>AI Top 40 is the most advanced AI-driven radio show in the world. AI leads every stage of the production process including tracking competitors, compiling the chart, creating the content, presenting the show and playing it out.</p>
            </div>
        </div>
    )
}

export default HeaderText