import programImg from "../../assets/img/thumbnail2.jpeg";
import countryImg from "../../assets/img/country.webp";
import edmImg from "../../assets/img/edm.avif";
import hiphopImg from "../../assets/img/hiphop.jpg";
import latinImg from "../../assets/img/latin.jpg";

const programs = [
  {
    id: 1,
    title: "COUNTRY CHART",
    description: "Details Soon",
    img: countryImg,
  },
  {
    id: 2,
    title: "TIKTOK CHART",
    description: "Details Soon",
    img: programImg,
  },
  {
    id: 3,
    title: "EDM CHART",
    description: "Details Soon",
    img: edmImg,
  },
  {
    id: 4,
    title: "LATIN CHART",
    description: "Details Soon",
    img: latinImg,
  },
  {
    id: 5,
    title: "HIP HOP CHART",
    description: "Details Soon",
    img: hiphopImg,
  },
];

export default programs;
