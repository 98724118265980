import React from "react";

const ServiceCard = ({ name, icon }) => {
  return (
    <div className="flex flex-col items-center p-2 w-full bg-white overflow-hidden shadow-lg rounded-3xl">
      <div className="px-6 py-4 ">{icon}</div>
      <div className="px-6 py-4">
        <p className="text-theme-primary text-center font-bold capitalize text-xl md:text-2xl">
          {name}
        </p>
      </div>
    </div>
  );
};

export default ServiceCard;
