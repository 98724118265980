import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpotify,
  faGooglePlay,
  faApple,
} from "@fortawesome/free-brands-svg-icons";
import Chart from "./chart";

function Live() {
  return (
    <section className="min-h-full max-w-7xl mx-auto mt-[-36px] relative z-20 bg-theme-primary flex flex-col md:flex-row justify-center items-start md:p-14 pb-0 mb-12 md:px-24 text-theme-primary">
      <div className="bg-white w-full md:w-1/2 px-7 py-10 md:p-14 md:rounded-3xl">
        <h1 className="text-4xl sm:text-5xl font-black">
          CHART PRESENTED BY AI
        </h1>
        <p className="my-12">
          Welcome to the world's first chart show produced by AI! This
          groundbreaking music show features the top 40 songs, all curated by
          state-of-the-art AI algorithms. With this show, you'll get a unique
          blend of the latest hits from a wide range of music genres, from pop
          and rock to hip-hop and electronic. And the best part? You can listen
          to the chart on demand, anytime, anywhere. With just a few clicks,
          you'll be able to experience the future of music and discover new
          songs and artists. So sit back, relax, and enjoy the AI-driven music
          revolution!
          <br /> <br />
          Download our app from the App Store or Google Play today and
          experience the future of radio! With our AI-created station, you can
          enjoy the ultimate dance party anytime, anywhere.
        </p>
        <div className="flex text-theme-primary text-3xl w-1/2 justify-between items-center">
          <FontAwesomeIcon
            icon={faGooglePlay}
            className="hover:-translate-y-2 ease-in-out cursor-pointer"
          />
          <FontAwesomeIcon
            icon={faApple}
            className="hover:-translate-y-2 ease-in-out cursor-pointer"
          />
          <FontAwesomeIcon
            icon={faSpotify}
            className="hover:-translate-y-2 ease-in-out cursor-pointer"
          />
          {/* <FontAwesomeIcon icon={faYoutube} className='hover:-translate-y-2 ease-in-out cursor-pointer' /> */}
        </div>
      </div>
      <div className="w-full h-full min-h-[600px] md:w-1/2 p-4 pb-0 md:px-14 text-theme-third flex flex-col items-start">
        <h2 className="text-white text-3xl font-black">UPDATED</h2>
        <div className="flex items-center mb-12">
          <p>Sundays at 4pm CET</p>
          <div className="w-32 h-[1px] bg-theme-third mx-5">
            <div className="w-1/2 h-full bg-white"></div>
          </div>
          <p>7pm CET</p>
        </div>

        <h5 className="uppercase text-white font-semibold">AI HOST</h5>
        <p className="text-3xl font-black">NEXUS JAMES</p>
        <Chart />
      </div>
    </section>
  );
}

export default Live;
