import React from "react";
import ServiceCard from "./ServiceCard";
import ChartIcon from "./icons/Chart";
import Tracker from "./icons/Tracker";
import Voice from "./icons/Voice";
import Producer from "./icons/Producer";
import Language from "./icons/Language";
import Content from "./icons/Content";

const data = [
  {
    id: 1,
    icon: <Tracker size={130} />,
    name: "AI Music Tracker",
  },
  {
    id: 2,
    icon: <ChartIcon size={130} />,
    name: "AI Chart Compiler",
  },
  {
    id: 3,
    icon: <Producer size={130} />,
    name: "AI Show Prep Content Producer",
  },
  {
    id: 4,
    icon: <Language size={130} />,
    name: "AI Broadcaster Language Model",
  },
  {
    id: 5,
    icon: <Voice size={130} />,
    name: "AI Broadcaster Voice Generator",
  },
  {
    id: 6,
    icon: <Content size={130} />,
    name: "AI Dynamic Audio Producer",
  },
];
const OurService = () => {
  return (
    <div className="mt-[-36px] grid grid-flow-row lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4 p-7  md:p-14 md:px-24">
      {data.map((data, index) => {
        return (
          <ServiceCard
            id={data.id}
            icon={data.icon}
            name={data.name}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default OurService;
