import playImg from "../assets/img/play-btn-icon.svg";
import pauseImg from "../assets/img/pause-btn-icon.svg";

export default function ListenLive({ togglePlay, playAudio }) {
  return (
    <div
      onClick={togglePlay}
      className={`z-20 flex cursor-pointer flex-row gap-3 items-center lg:items-end lg:rotate-90 absolute top-0 
      lg:top-1/2 right-0 -translate-x-[30%] lg:translate-x-[65%] md:-translate-x-[10%] -translate-y-[105%] 
      lg:translate-y-[0%] 
      text-white text-2xl md:text-3xl
      lg:text-5xl uppercase font-normal`}
    >
      <img
        className="aspect-square lg:-rotate-90 w-6 lg:w-9"
        src={playAudio ? pauseImg : playImg}
        alt="play"
      />
      <p className="font-bold max-w-[100px] sm:max-w-[500px] lg:text-right whitespace-nowrap">
        listen <span className="opacity-70 font-normal">live</span>
      </p>
    </div>
  );
}
