const people = [
	{
		"title": "PAINT THE TOWN RED",
		"artist": "DOJA CAT",
		"image": "https://i.discogs.com/u_bYZmTQ-mdqflXJ4vWgif7Z50ISQr46p9Kb3lIaF88/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI3ODc0/OTE0LTE2OTExNjg1/MDMtNDk5NS5qcGVn.jpeg",
		"week": 1,
		"lastWeek": 2
	  },
	  {
		"title": "GREEDY",
		"artist": "TATE MCRAE",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/23/07/92/23079247-25be-3098-ef53-78e7d0fe7406/196871341653.jpg/225x225bb.jpg",
		"week": 2,
		"lastWeek": 3
	  },
	  {
		"title": "USED TO BE YOUNG",
		"artist": "MILEY CYRUS",
		"image": "https://i.discogs.com/LOzJ2OJbf5MzEwpycyWrja0F2WwXPWjNp0vHwAyI_BY/rs:fit/g:sm/q:90/h:522/w:480/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4NjE1/MDc4LTE2OTc0OTk0/ODgtNTUxOC5qcGVn.jpeg",
		"week": 3,
		"lastWeek": 4
	  },
	  {
		"title": "PRADA",
		"artist": "RAYE, CASSO, D-BLOCK EUROPE",
		"image": "https://is4-ssl.mzstatic.com/image/thumb/Music126/v4/81/59/f7/8159f7b5-52c0-003b-92c2-04c98a6147a1/196871373258.jpg/225x225bb.jpg",
		"week": 4,
		"lastWeek": 7
	  },
	  {
		"title": "ON MY LOVE",
		"artist": "DAVID GUETTA, ZARA LARSSON",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/8f/97/2e/8f972e03-5682-6508-2c16-ea83df390fd1/196871437585.jpg/225x225bb.jpg",
		"week": 5,
		"lastWeek": 19
	  },
	  {
		"title": "NOW THAT WE DON'T TALK (TAYLOR'S VERSION) [FROM THE VAULT]",
		"artist": "TAYLOR SWIFT",
		"image": "https://i.discogs.com/kNvJtDrevV0YCIvHAIEVvHe295HxtM4sXQT-KRAaErU/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4NzEx/NzQ0LTE2OTkwOTcz/MTgtNTM4MC5qcGVn.jpeg",
		"week": 6,
		"lastWeek": 6
	  },
	  {
		"title": "WATER",
		"artist": "TYLA",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/1e/c9/5b/1ec95bbc-a56f-cbef-070c-ddec9f4fdd3c/196871296205.jpg/225x225bb.jpg",
		"week": 7,
		"lastWeek": 8
	  },
	  {
		"title": "ALL I WANT FOR CHRISTMAS IS YOU",
		"artist": "MARIAH CAREY",
		"image": "https://i.discogs.com/x6oD8OIj7bbfWmIkgilzlZijjelfkUZOriD8tYP4zjc/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTE0NTI0/NDYwLTE1NzYzMjk1/NjAtNTc2MC5wbmc.jpeg",
		"week": 8,
		"lastWeek": 13
	  },
	  {
		"title": "WHAT IT IS (BLOCK BOY)",
		"artist": "DOECHII FEAT. KODAK BLACK",
		"image": "https://i.discogs.com/UkbgA5v9bLdHFU7zzSW6eiPXCYBu8uWy06EvtxUHu84/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI2NDc0/NDcxLTE2NzkyNDc2/NTctODI1OC5qcGVn.jpeg",
		"week": 9,
		"lastWeek": 9
	  },
	  {
		"title": "STRANGERS",
		"artist": "KENYA GRACE",
		"image": "https://i.discogs.com/RIo8yZk9A4zjxfVTdZI6dPGW9Z9L9p5EJjLE6SRaNR4/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4MjQ0/NjI2LTE2OTQ1NDYw/NzktNTc3MS5qcGVn.jpeg",
		"week": 10,
		"lastWeek": 16
	  },
	  {
		"title": "POINTLESS",
		"artist": "LEWIS CAPALDI",
		"image": "https://i.discogs.com/2vv3J5QyvD7fFn_RT1o2L2-17w76ygMn232mPKDN0rI/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI1MzQ4/ODEzLTE2Njk5OTc4/MzMtNjAyNy5qcGVn.jpeg",
		"week": 11,
		"lastWeek": 14
	  },
	  {
		"title": "HOUDINI",
		"artist": "DUA LIPA",
		"image": "https://i.discogs.com/jlgAPu-uexUwchTIwQqP4-_IGk9m1qD0UZ1dtCKIrLY/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4ODUx/NTg2LTE2OTk1NzA2/NjQtOTcwNi5qcGVn.jpeg",
		"week": 12,
		"lastWeek": 15
	  },
	  {
		"title": "SATELLIT",
		"artist": "DARIN",
		"image": "https://i.discogs.com/TsQvx_AhuODSZJet74X-qYqk9Bp6GzL6QnuxZj9evek/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4MDIw/MjIyLTE2OTI0NzI2/MjMtMjQzMy5qcGVn.jpeg",
		"week": 13,
		"lastWeek": 17
	  },
	  {
		"title": "LOVIN ON ME",
		"artist": "JACK HARLOW",
		"image": "https://i.discogs.com/uSUYp_5WsL8mdRGyze2gVHy_WcDXqc6GcvWufn2TLvs/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4ODU2/MTcwLTE2OTk2MTg3/NDQtNTM4NC5qcGVn.jpeg",
		"week": 14,
		"lastWeek": 20
	  },
	  {
		"title": "EVERLEAVE",
		"artist": "ALEXANDRA KAY",
		"image": "https://i.discogs.com/4B7SuHNkBEuLyff7EHxuKJ9TXB7NQjU8qBjiaWE2C5g/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4NTQ2/ODA3LTE2OTY4Nzkz/NDAtOTU2OC5wbmc.jpeg",
		"week": 15,
		"lastWeek": 18
	  },
	  {
		"title": "LIL BOO THANG",
		"artist": "PAUL RUSSELL",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/ea/5d/dc/ea5ddcb8-66c6-c3ab-e268-41e2d8d675a0/196871378383.jpg/225x225bb.jpg",
		"week": 16,
		"lastWeek": 21
	  },
	  {
		"title": "STAY",
		"artist": "KRIS KROSS AMSTERDAM",
		"image": "https://i.discogs.com/e7se-Qofr8JWicWqSlwplDoXqoSoGYzltp0BQQu6_ao/rs:fit/g:sm/q:90/h:600/w:592/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4ODE3/MDkyLTE2OTkyNjUx/NDYtNzYwOS5qcGVn.jpeg",
		"week": 17,
		"lastWeek": 22
	  },
	  {
		"title": "ADORE U",
		"artist": "FRED AGAIN.., OBONGJAYAR",
		"image": "https://i.discogs.com/CDdcSDpHjKHQWzIIeOBD5sGp4gD1hWOC_Oj_CNJ2468/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI3OTUx/MTE0LTE2OTE4MzQz/NzMtMzgyMC5qcGVn.jpeg",
		"week": 18,
		"lastWeek": 1
	  },
	  {
		"title": "AMERICAN TOWN",
		"artist": "ED SHEERAN",
		"image": "https://i.discogs.com/Z9oG3ouoUOAVgIv4mNXktxEUqw9k2yWOquQ-N8p7i10/rs:fit/g:sm/q:90/h:588/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4NDMx/OTM0LTE2OTU5NzYw/OTQtNjY5NS5qcGVn.jpeg",
		"week": 19,
		"lastWeek": 24
	  },
	  {
		"title": "BETTER PLACE (FROM TROLLS BAND TOGETHER)",
		"artist": "*NSYNC, JUSTIN TIMBERLAKE",
		"image": "https://i.discogs.com/USAub9J2La2mjaMnmOxsR2GbZHTh-LnSE7kVCdYqRuM/rs:fit/g:sm/q:90/h:594/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4OTUy/MDk4LTE3MDAzMjIz/OTktNDU5MS5qcGVn.jpeg",
		"week": 20,
		"lastWeek": 26
	  },
	  {
		"title": "(IT GOES LIKE) NANANA",
		"artist": "PEGGY GOU",
		"image": "https://i.discogs.com/c-1lbqVrXrX3zAsXvMs6kfDJ_FQhC5gAKwVM_zzMgKE/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI3NTk1/MjAzLTE2ODg2MzY3/NTItNTEwNy5qcGVn.jpeg",
		"week": 21,
		"lastWeek": 5
	  },
	  {
		"title": "DRUMS",
		"artist": "JAMES HYPE & KIM PETRAS",
		"image": "https://i.discogs.com/x2_bNU4A_6m7MEc_JUASeBVyfhge5zgCCHmY-8IWvKI/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4NjY5/ODUyLTE2OTc5NzQ4/ODAtMzY4NS5qcGVn.jpeg",
		"week": 22,
		"lastWeek": 28
	  },
	  {
		"title": "CRUEL SUMMER",
		"artist": "TAYLOR SWIFT",
		"image": "https://i.discogs.com/tMAoZTA6_uGQmWPHpjlVQNt3hVhMcLjt0zMNLElAcac/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTE0MDI0/NTI2LTE1NjY1NTYw/MDctNjE2MS5qcGVn.jpeg",
		"week": 23,
		"lastWeek": 27
	  },
	  {
		"title": "ROCK MY BODY",
		"artist": "R3HAB & INNA & SASH!",
		"image": "https://i.discogs.com/jWTbXBqf3FxhSyMA2XZtlfMTma7hMoRe_tXavx3VjHg/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI3MjEy/Nzk5LTE2ODUyNjM4/ODMtNTIzNy5qcGVn.jpeg",
		"week": 24,
		"lastWeek": 23
	  },
	  {
		"title": "VAMPIRE",
		"artist": "OLIVIA RODRIGO",
		"image": "https://i.discogs.com/wAfF9R45jvmzqokpovm0IwV-a1Tg8LLGLcLyK1qyBSU/rs:fit/g:sm/q:90/h:544/w:544/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI3NTMz/NjM3LTE2ODgwOTkw/NTUtNzgwNi5qcGVn.jpeg",
		"week": 25,
		"lastWeek": 30
	  },
	  {
		"title": "ALIVE",
		"artist": "TOBY ROMEO, DECLAN J DONOVAN",
		"image": "https://i.discogs.com/6-LY4q-5j8jbvLrJUJGhG6_IM6E2ScEz9HafJ0q3opk/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4MjIy/OTkwLTE2OTQzNTI5/ODItOTAyMy5wbmc.jpeg",
		"week": 26,
		"lastWeek": 29
	  },
	  {
		"title": "STANDING NEXT TO YOU",
		"artist": "JUNG KOOK",
		"image": "https://i.discogs.com/DkmOm-SdVEVrA5wMgzbNmc8_vOdP5QtBO6aK6IRA_6o/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI5MDIx/NDUyLTE3MDA5NjA5/MzMtNzM0NS5qcGVn.jpeg",
		"week": 27,
		"lastWeek": 31
	  },
	  {
		"title": "SINGLE SOON",
		"artist": "SELENA GOMEZ",
		"image": "https://i.discogs.com/rQYErVcqvtwqoKSPyEoiRa-8_h904QErTfhQNKH8-6s/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4MDgy/MTQzLTE2OTMwNTM3/MDktODkwMS5qcGVn.jpeg",
		"week": 28,
		"lastWeek": 10
	  },
	  {
		"title": "CALM DOWN",
		"artist": "REMA & SELENA GOMEZ",
		"image": "https://i.discogs.com/2ElcJtgiE4UMyVGEUc3DYThZtyBwJoVUVTQUiXChqFM/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI1NDYy/NjA2LTE2NzEwMzE2/MTUtNDUwMC5qcGVn.jpeg",
		"week": 29,
		"lastWeek": 25
	  },
	  {
		"title": "IS IT LOVE",
		"artist": "LOREEN",
		"image": "https://i.discogs.com/AmU6PzgXxmp5a8VVDr7JPEmQQdZ2a82-4FCSCTWMomc/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4NTg4/MTc3LTE2OTcyNzU5/MTQtMTkyNS5qcGVn.jpeg",
		"week": 30,
		"lastWeek": 38
	  },
	  {
		"title": "CAN'T CATCH ME NOW",
		"artist": "OLIVIA RODRIGO",
		"image": "https://i.discogs.com/6wP8ijJeUo7eT9A58YjW4tnYLSp6SQLqgfATTge6bC8/rs:fit/g:sm/q:90/h:587/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4ODMx/NTI4LTE2OTk0NzQ4/MDUtMTA5MC5qcGVn.jpeg",
		"week": 31,
		"lastWeek": 11
	  },
	  {
		"title": "PSYKOS",
		"artist": "MOLLY SANDEN",
		"image": "https://i.discogs.com/n8rJTP6QqnL_clXvqGrRBTDT_7pt3vpEtnnI5bzGfqo/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTIwNTQy/Nzg2LTE2MzM4ODQ4/NTgtNTIzNi5qcGVn.jpeg",
		"week": 32,
		"lastWeek": 33
	  },
	  {
		"title": "WHAT WAS I MADE FOR? [FROM THE MOTION PICTURE BARBIE]",
		"artist": "BILLIE EILISH",
		"image": "https://i.discogs.com/v_6VkfUtMwhup5dqg5KSWUHJkm9aAND5GtaC2VfGnPE/rs:fit/g:sm/q:90/h:440/w:440/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI3NjYz/MzY2LTE2ODkyNTgx/NjAtOTcyNy5qcGVn.jpeg",
		"week": 33,
		"lastWeek": 34
	  },
	  {
		"title": "MY LOVE",
		"artist": "LEIGH-ANNE",
		"image": "https://i.discogs.com/XsfF5VE46gR1GibSCSMUOZtKGoaLDRPV07-QY50s0w8/rs:fit/g:sm/q:90/h:488/w:487/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4MjE1/MjgzLTE2OTQ2MjM1/ODctNTM4NC5qcGVn.jpeg",
		"week": 34,
		"lastWeek": 35
	  },
	  {
		"title": "STYLE (TAYLOR'S VERSION)",
		"artist": "TAYLOR SWIFT",
		"image": "https://i.discogs.com/kNvJtDrevV0YCIvHAIEVvHe295HxtM4sXQT-KRAaErU/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4NzEx/NzQ0LTE2OTkwOTcz/MTgtNTM4MC5qcGVn.jpeg",
		"week": 35,
		"lastWeek": 36
	  },
	  {
		"title": "RULE THE WORLD (EVERYBODY)",
		"artist": "NIIKO X SWAE, GUDFELLA, TEARS FOR FEARS, TIESTO",
		"image": "https://i.discogs.com/jJvSGC48dFykKPB-8sQOPP_ljsWtxjaYyykSArUK3uA/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4NzMw/ODE1LTE2OTg1MTc5/MjctNzg5MS5qcGVn.jpeg",
		"week": 36,
		"lastWeek": 37
	  },
	  {
		"title": "DANCE THE NIGHT",
		"artist": "DUA LIPA",
		"image": "https://i.discogs.com/XCD01pZdjgSkHERl8M9gsOH5RwPPfh0OjNbDcwlC2Ik/rs:fit/g:sm/q:90/h:500/w:500/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTEwNDQx/MTQxLTE0OTc0OTg0/ODgtNTc3NC5qcGVn.jpeg",
		"week": 37,
		"lastWeek": 12
	  },
	  {
		"title": "FIGHT LIKE A GIRL",
		"artist": "MOLLY HAMMAR",
		"image": "https://i.discogs.com/4W72dsjYzh2J97PYLWhUQA589__yXx9un6emm3ZbBeo/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4MDE2/MDk0LTE2OTI0NDU0/OTctNjIxNC5qcGVn.jpeg",
		"week": 38,
		"lastWeek": 32
	  },
	  {
		"title": "ANOTHER LOVE",
		"artist": "TOM ODELL",
		"image": "https://i.discogs.com/vu7rhtbOm7fFAdrAhukUgengNac8rVAmr16IX46TxA0/rs:fit/g:sm/q:90/h:522/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTQ2NzQ5/ODktMTM3MTg2MTkw/MC0yMTg0LmpwZWc.jpeg",
		"week": 39,
		"lastWeek": 39
	  },
	  {
		"title": "PRETTY GIRL",
		"artist": "ICE SPICE & REMA",
		"image": "https://i.discogs.com/1ZeE1eyIOg9RwHsFJRAtZpARFiYo21tLCbL135IdERk/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI4NTc5/MzQ1LTE2OTcyMDEy/MTItNTUxOC5qcGVn.jpeg",
		"week": 40,
		"lastWeek": 40
	  }
  ];

export default function Chart() {
  return (
    <div className="flex flex-col items-start h-full bg-white shadow-md rounded-md overflow-hidden">
      <div className="bg-gradient-to-r from-purple-600 to-indigo-600 border-b-2 px-4 py-3">
        <div className="flex justify-between items-center uppercase ">
          <div className="flex space-x-3">
            <p className="text-sm font-semibold text-white">Week</p>
            <p className="text-sm font-semibold text-white">Name</p>
          </div>
          <div>
            <p className="text-sm font-semibold text-white">Last Week</p>
          </div>
        </div>
      </div>

      <ul className="divide-y divide-gray-200 overflow-y-auto h-80 sm:h-full max-h-[518px]">
        {people.map((person, index) => (
          <li
            key={index}
            className="flex justify-between p-3 hover:bg-purple-100"
          >
            <div className="flex items-center">
              <p className="text-sm p-2 m-1 font-medium text-white bg-theme-third rounded-full">
                {String(person.week).padStart(2, "0")}
              </p>
              <img
                className="h-10 w-10 m-1 rounded-full"
                src={person.image}
                alt=""
              />
              <div className="flex flex-col items-start">
                <p className="text-sm font-medium text-gray-800">
                  {person.title}
                </p>
                <p className="text-sm text-gray-600">{person.artist}</p>
              </div>
            </div>
            <div className="flex items-center">
              <p className="text-sm font-medium text-gray-500 ml-2">
                {person.lastWeek}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
