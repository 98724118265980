import React, { useState } from "react";
import axios from "axios";

import TextBox from "./input/TextBox";
import Thumbnail from "../assets/img/thumbnail2.jpg";
import Select from "./input/Select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Contact() {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		type: '',
		message: ''
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// replace the below link with getform api
		const formURL = 'https://getform.io/f/7844afc9-36c2-428e-8bde-641a79c185b4';

		axios.post(formURL, formData)
			.then((response) => {
				toast("Form submitted successfully!!", { hideProgressBar: false, autoClose: 7000, type: 'Success' });
			})
			.catch((error) => {
				toast("Error submitting form!", { hideProgressBar: false, autoClose: 7000, type: 'error' });
				console.error('Error submitting form:', error);
				// Handle any errors that occurred during the form submission
			});
	};
	return (
		<section className='md:px-24  py-14 flex flex-col md:flex-row justify-between'>
			<div className='w-full md:w-[45%] border-dashed border-theme-secondary text-white border-4 p-4 md:p-4'>
				<form
					className='flex flex-col text-white'
					onSubmit={handleSubmit}
				>
					<h1 className='text-white text-5xl font-black uppercase mb-12' style={{ fontSize: "25px", textAlign: "center" }}>SHOUTOUTS</h1>
					<TextBox
						type="text"
						name="name"
						placeholder="Name"
						value={formData.name}
						onChange={handleChange}
					/>
					<TextBox
						type="email"
						name="email"
						placeholder="E-mail"
						value={formData.email}
						onChange={handleChange}
					/>
					<Select
						name="type"
						options={["Programming", "Sales", "Technical"]}
						value={formData.type}
						onChange={handleChange}
					/>

					<textarea
						name="message"
						placeholder="Your Message"
						value={formData.message}
						onChange={handleChange}
						className='bg-[#4B4B4B] mb-4 p-3 '
					/>
					<button
						type='submit'
						className='bg-theme-accent px-20 py-2 uppercase font-bold w-min text-black'
						style={{ width: "100%", alignSelf: "center", textAlign: "center" }}
					>
						submit
					</button>
				</form>
			</div>
			<div className='w-full md:w-1/2 p-5 mt-10 text-white md:mt-0 w-full md:w-[45%] border-dashed border-theme-secondary border-4 p-4 md:p-4' style={{ alignItems: "center", justifyContent: "center" }}>
				<h1 className='text-5xl font-black uppercase mb-3' style={{ fontSize: "25px", textAlign: "center" }}>COMPETITIONS</h1>
				<p className='text-theme-accent text-xl' style={{ fontWeight: "bold", textAlign: "center" }}>
					Listen the AI Top40 to WIN<br />

				</p>
				<div className='flex flex-col md:flex-col mt-10 ' style={{ alignItems: "center", justifyContent: "center" }}>
					<img src={Thumbnail} alt='thumbnail' className='w-100 h-50 object-contain' style={{ width: "100%", height: "200px" }} />
					<div className='md:px-4 uppercase flex flex-col items-start' style={{ alignItems: "center" }}>
						<p className='bg-theme-accent text-black px-6 mb-2 mt-5'> WEEK 48 | 2023</p>
						<h1 className='text-4xl font-extrabold'>YEAR OF APPLE MUSIC</h1>
						<p>A subscription of music</p>
						
					</div>
				</div>
			</div>
			<ToastContainer />
		</section>
	);
}

export default Contact;
