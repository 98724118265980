import React from "react";

function Select({ title, options, name, onChange }) {
	return (
		<select
			className='select bg-[#4B4B4B] mb-4 p-3'
			name={name}
			onChange={onChange}
		>
			{options.map((op, idx) => (
				<option key={idx}>{op}</option>
			))}
		</select>
	);
}

export default Select;
